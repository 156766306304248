<template>
  <div class="input-selection">
    <step-heading
      :step="step"
      :title="title"
    />
    <el-radio-group
      :value="value"
      :disabled="loading"
      @input="(value) => $emit('input', value)"
    >
      <el-radio-button
        v-for="(item, i) in options"
        :key="i"
        :label="item.id"
      >
        <img
          v-if="item.icon"
          :src="item.icon"
          :alt="item.id"
        >{{ item.label }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import StepHeading from '@/views/pages/AIServices/components/StepHeading/Index.vue';

export default {
  name: 'InputSelection',
  components: { StepHeading },
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-selection {
  .el-radio {
    &-group {
      display: flex;
      flex-direction: row;
      margin-top: 0.16rem;
      overflow: auto;

      :last-child {
        border-right: none;
      }
    }
    &-button {
      display: flex;
      flex: 1;
      border-right: 1px solid #D8E1F0;
    }
  }

  /deep/ .is-active .el-radio-button__inner {
    background-color: $button-primary-bg;
    color: $text-dark-bg;
  }

  /deep/ .el-radio-button__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #605f74;
    background: $button-light-gray-bg;
    font-weight: 600;
    font-size: 0.14rem;
    border: none;
    span {
      display: flex;
      flex: 1;
      font-weight: 500;
      font-size: 0.14rem;
      color: rgba(96, 95, 116, 0.5);
    }

    img {
      $icon-size: 0.15rem;
      width: $icon-size;
      height: $icon-size;
      margin-right: 0.04rem;
    }
  }
}
</style>

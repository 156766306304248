<template>
  <div id="ai-services_asa_tts">
    <service-template
      :class="{'asa-example-rtl': currentLanguage===$consts.lang.AR}"
      :run="run"
      :run-disabled="runDisabled"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/TTS.svg')"
      service="asa"
    >
      <template v-slot:header>
        {{ $t('services.TTS.header') }}
      </template>
      <template v-slot:left>
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="currentLanguage"
          :loading="loading"
          :disabled="comingSoonLanguages"
        />
        <input-selection
          class="voice-category"
          title="Choose Voice Category"
          :step="2"
          :value="voice"
          :options="voices[currentLanguage]"
          :loading="loading"
          @input="(value) => voice = value"
        />
        <example-text
          :step="computeStep(2)"
          :items="textExamples"
          :current-example.sync="currentExample"
          :example-text.sync="exampleText"
          :loading="loading"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="computeStep(3)"
          :service-name="$t('services.TTS.header')"
          :loading="loading"
        >
          <audio-player
            v-if="stream"
            :blob="stream"
            :on-play-pause="handlePlayPause"
            :on-create="onWaveSurferCreate"
          />
          <span v-if="errMessage">{{ errMessage }}</span>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import InputSelection from '@/views/pages/AIServices/components/InputSelection/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ExampleText from '@/views/pages/AIServices/components/ExampleText/Index.vue';
import AudioPlayer from '@/components/AudioPlayer/Index.vue';
import { mergeBlobs } from '@/service/audio/converter';

export default {
  name: 'TextToSpeech',
  components: {
    ExampleText,
    GeneratedResult,
    InputLanguage,
    InputSelection,
    ServiceTemplate,
    AudioPlayer,
  },
  data() {
    return {
      availableLanguages: [],
      comingSoonLanguages: [],
      currentLanguage: this.$consts.lang.EN,
      currentExample: null,
      examples: [],
      exampleText: '',
      loading: false,
      ttsService: null,
      stream: null,
      errMessage: '',
      waveSurferInstance: null,
      voice: this.$api.asa.voice[this.$consts.lang.EN][0].id,
      voices: this.$api.asa.voice,
    };
  },
  computed: {
    textExamples() {
      return (this.examples[this.currentLanguage] || []).map((el, index) => ({
        id: index,
        value: el,
      }));
    },
    runDisabled() {
      return !this.exampleText || !this.currentLanguage;
    },
  },
  watch: {
    currentLanguage() {
      this.currentExample = 0;
      this.voice = this.$api.asa.voice[this.currentLanguage][0].id;
    },
  },
  async created() {
    ({ default: this.examples } = await import('@/../public/examples/ASA/TTS.json'));
    this.availableLanguages = Object.keys(this.examples);
    this.currentLanguage = this.availableLanguages[0];
    this.currentExample = 0;
    this.ttsService = new this.$api.asa.TTS();

    this.ttsService.streamSpeech(this.streamSpeech);
  },
  methods: {
    computeStep(step) {
      return this.currentLanguage === this.$consts.lang.AR ? step + 1 : step;
    },
    async run() {
      this.loading = true;

      this.stream = null;
      this.blobsArray = [];
      this.errMessage = '';
      await this.ttsService.getNewSpeechFromRest({
        lang: this.currentLanguage,
        text: this.exampleText,
        voice: this.voice,
      });
    },

    async streamSpeech(data) {
      if (!this.stream) return this.updateStream(data);

      const merged = await mergeBlobs([this.stream, data]);

      return this.updateStream(merged);
    },
    updateStream(data) {
      const blob = new Blob([data], { type: 'audio/wav; codecs=MS_PCM' });
      this.stream = blob;
      this.loading = false;
    },
    handlePlay(wavesurfer) {
      if (this.audio) this.audio.disable();

      wavesurfer.on('ready', () => {
        wavesurfer.play();
      });
    },
    handlePlayPause(isPlaying) {
      if (isPlaying) return this.audio.disable();

      return this.handlePlay(this.wavesurfer);
    },
    onWaveSurferCreate(instance) {
      this.waveSurferInstance = instance;
      return this.waveSurferInstance;
    },
  },
};

</script>

<style lang="scss" scoped>
#ai-services_asa_tts {
  background: $background-secondary;

  .voice-category {
    margin-top: 0.16rem;
  }

  .asa-example-rtl {
    ::v-deep {
      .el-input > input {
        font-family: Cairo !important;
        direction: rtl;
      }
      textarea.el-textarea__inner {
        font-family: Cairo !important;
      }
    }
  }
}
</style>
